
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { StringsMixin, VuetifyMixin } from '@/mixins';

@Component
export default class SideNavSectionTitle extends Mixins(VuetifyMixin, StringsMixin) {
	@Prop({type: Boolean, default: false}) mini: boolean;
	@Prop({type: String}) icon;
	@Prop({type: String}) title;
	@Prop({type: String}) textColor;
	@Prop({type: Boolean}) group;

	defaultTextColor: string = 'baColorGray8';
	get HasIcon(): boolean {
		return this.IsNotEmpty(this.icon);
	}
	get HasTitle(): boolean {
		return this.IsNotEmpty(this.title);
	}
	get IconColor(): string {
		if( this.IsEmpty(this.textColor) ) return this.defaultTextColor;
		return this.textColor;
	}
	get TextColor(): string {
		if( this.IsEmpty(this.textColor) ) return this.defaultTextColor;
		return this.textColor;
	}
}
