
import { Component, Prop, Mixins } from 'vue-property-decorator';
import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import {AppHostnameMixin, BAIconsMixin, StringsMixin, VuetifyMixin} from '@/mixins';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { orgAdminStore } from '@/store';
import { TeamModel } from '@/models';

@Component({
	components: {
		BestAthletesLogo,
		SideNavSectionTitle,
	},
})
export default class OrgAdminSideNav extends Mixins(BAIconsMixin, AppHostnameMixin, StringsMixin, VuetifyMixin){
	@Prop({ default: true, type: Boolean }) value: boolean;
	mini = false;
	listTeams: boolean = false;
	listOrg: boolean = false;

	async created(){
		await orgAdminStore.loadOrganizations();
		if( this.IsOrgOpen ) await orgAdminStore.loadOrganizationTeams({organizationId: this.OrganizationId});
	}

	get IsOrgsLoaded(): boolean{
		return orgAdminStore.organizationsInitialized;
	}
	get Organizations(): OrganizationModel[]{
		return orgAdminStore.organizations;
	}
	get IsOrgOpen(): boolean {
		return this.IsNotEmpty(this.OrganizationId);
	}
	get OrgTeams(): Array<TeamModel> {
		return orgAdminStore.teamList;
	}
	get IsOrgTeamsLoaded(): boolean {
		return orgAdminStore.loadOrganizationTeamsInitialized;
	}

	get OrganizationId(): string | null{
		if(this.$route.params.organizationId){
			return this.$route.params.organizationId;
		}
		return null;
	}

	async gotoOrgSettings() {
		this.$router.push({ name: OrgAdminRoutes.OrgAdminSettings }).catch(e => {})
		this.listTeams = false;
		this.listOrg = true;
	}
	async gotoOrgAdminDashboard() {
		this.$router.push({ name: OrgAdminRoutes.OrgAdminDashboard }).catch(e => {})
		this.listTeams = false;
		this.listOrg = false;
	}
	async gotoOrganization(org: OrganizationModel) {
		this.$router.push({ name: OrgAdminRoutes.OrgAdminManageTeams,
			params: { organizationId: org.id }
		}).catch(e => {})

		this.listTeams = true;
	}
	async gotoTeam(team: TeamModel) {
		if( !this.IsOrgOpen ) return;

		this.$router.push({ name: OrgAdminRoutes.TeamDashboard,
			params:{ teamId: team.id }
		}).catch(e => {})
	}
	async gotoAthletes() {
		if( !this.IsOrgOpen ) return;

		this.$router.push({ name: OrgAdminRoutes.OrgAdminManagePlayers,
			params: { organizationId: this.OrganizationId }
		}).catch(e => {})
	}
	async gotoStaff() {
		if( !this.IsOrgOpen ) return;

		this.$router.push({ name: OrgAdminRoutes.OrgAdminManageStaff,
			params:{ organizationId: this.OrganizationId }
		}).catch(e => {})
	}

	get SectionTitleColor(): string {
		return this.getColor(this.IsOrgOpen? '' : 'baColorGray1');
	}
	createTeam(): void{
		this.$router.push({
			name: OrgAdminRoutes.TeamCreate,
		});
	}
}
