import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { orgAdminStore } from '@/store';
import { isEmpty } from '@/pipes';

async function resolveOrganizationFromUrl(to, __, next): Promise <void> {
	const { organizationId } = to.params;
	if(organizationId) {
		orgAdminStore.loadOrganizationById({ organizationId });
	}
	next();
}

@Component({
	beforeRouteEnter: resolveOrganizationFromUrl,
	beforeRouteUpdate: resolveOrganizationFromUrl,
})
export class OrgResolverMixin extends Vue {
	@Prop({ required: true }) organizationId: string;

	async loadCurrentOrganization(): Promise<void> {
		if( isEmpty(this.organizationId) ) return;
		await orgAdminStore.loadOrganizationById({organizationId: this.organizationId});
		await this.organizationLoadComplete();
	}
	async organizationLoadComplete() {
	}

	get IsOrganizationLoaded(): boolean {
		return orgAdminStore.organizationLoaded;
	}
	get CurrentOrganizationLoading(): boolean{
		return orgAdminStore.loadOrganizationByIdLoading;
	}

	get CurrentOrganization(): OrganizationModel {
		if( isEmpty(this.organizationId) ) return undefined;

		return orgAdminStore.organizations.find(o => o.id === this.organizationId);
	}

}
