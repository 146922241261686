/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';
import { StaffMember } from '@/models/team/StaffMember';
import { OrganizationCreateOptions, PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { TeamModel } from '@/models/team';
import router from '@/router/router';
import { AthleteProfileModel } from '../../models/athlete/AthleteProfileModel';

const Mutations = {
	LOAD_ORGANIZATIONS: 'LOAD_ORGANIZATIONS',
	LOAD_ORGANIZATIONS_SUCCESS: 'LOAD_ORGANIZATIONS_SUCCESS',
	LOAD_ORGANIZATIONS_FAILURE: 'LOAD_ORGANIZATIONS_FAILURE',
	PATCH_ORGANIZATION: 'PATCH_ORGANIZATION',
	PATCH_ORGANIZATION_SUCCESS: 'PATCH_ORGANIZATION_SUCCESS',
	PATCH_ORGANIZATION_FAILURE: 'PATCH_ORGANIZATION_FAILURE',
	LOAD_ORGANIZATION_BY_ID: 'LOAD_ORGANIZATION_BY_ID',
	LOAD_ORGANIZATION_BY_ID_SUCCESS: 'LOAD_ORGANIZATION_BY_ID_SUCCESS',
	LOAD_ORGANIZATION_BY_ID_FAILURE: 'LOAD_ORGANIZATION_BY_ID_FAILURE',
	LOAD_ORGANIZATION_STAFF: 'LOAD_ORGANIZATION_STAFF',
	LOAD_ORGANIZATION_STAFF_SUCCESS: 'LOAD_ORGANIZATION_STAFF_SUCCESS',
	LOAD_ORGANIZATION_STAFF_FAILURE: 'LOAD_ORGANIZATION_STAFF_FAILURE',
	LOAD_ORGANIZATION_TEAMS: 'LOAD_ORGANIZATION_TEAMS',
	LOAD_ORGANIZATION_TEAMS_SUCCESS: 'LOAD_ORGANIZATION_TEAMS_SUCCESS',
	LOAD_ORGANIZATION_TEAMS_FAILURE: 'LOAD_ORGANIZATION_TEAMS_FAILURE',
	REMOVE_STAFF: 'REMOVE_STAFF',
	REMOVE_STAFF_SUCCESS: 'REMOVE_STAFF_SUCCESS',
	REMOVE_STAFF_FAILURE: 'REMOVE_STAFF_FAILURE',
	QUERY_ORGANIZATION_PLAYERS: 'QUERY_ORGANIZATION_PLAYERS',
	QUERY_ORGANIZATION_PLAYERS_SUCCESS: 'QUERY_ORGANIZATION_PLAYERS_SUCCESS',
	QUERY_ORGANIZATION_PLAYERS_FAILURE: 'QUERY_ORGANIZATION_PLAYERS_FAILURE',
	REINDEX_LEADERBOARD: 'REINDEX_LEADERBOARDS',
	REINDEX_LEADERBOARD_SUCCESS: 'REINDEX_LEADERBOARDS_SUCCESS',
	REINDEX_LEADERBOARD_FAILURE: 'REINDEX_LEADERBOARDS_FAILURE',
}

const name = 'OrgAdminStore';

if (store.state[name]) {
	store.unregisterModule(name)
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class OrgAdminModule extends VuexModule {

	@Action({rawError: true}) navigateToRoot(){
		router.push('/');
	}

	organizationsLoading: boolean = false;
	organizationsInitialized: boolean = false;
	/** Total Organizations in current query */
	totalOrganizations: number = 0;
	organizations: OrganizationModel[] = [];
	@Action({
		rawError: true,
	}) async loadOrganizations(): Promise<void> {
		this.context.commit(Mutations.LOAD_ORGANIZATIONS);
		try {
			const orgs = await organizationApi.findAllWithAccess();
			this.context.commit(Mutations.LOAD_ORGANIZATIONS_SUCCESS, { orgs });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.LOAD_ORGANIZATIONS_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_ORGANIZATIONS](): void {
		this.organizationsLoading = true;
	}
	@Mutation [Mutations.LOAD_ORGANIZATIONS_SUCCESS]({ orgs }: { orgs: OrganizationModel[] }): void {
		this.organizations = orgs.map(o => new OrganizationModel().load(o));
		this.totalOrganizations = orgs.length;
		this.organizationsLoading = false;
		this.organizationsInitialized = true;
	}
	@Mutation [Mutations.LOAD_ORGANIZATIONS_FAILURE](error: any): void {
		this.organizationsLoading = false;
	}

	loadOrganizationByIdLoading: boolean = false;
	organizationLoaded: boolean = false;
	@Action({
		rawError: true,
	}) async loadOrganizationById({ organizationId }: { organizationId: string }): Promise<void> {
		this.context.commit(Mutations.LOAD_ORGANIZATION_BY_ID);
		try {
			const organizationFound = await organizationApi.findById(organizationId);
			const organization = new OrganizationModel().load(organizationFound);
			await this.context.commit(Mutations.LOAD_ORGANIZATION_BY_ID_SUCCESS, { organization });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.LOAD_ORGANIZATION_BY_ID_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_ORGANIZATION_BY_ID](): void {
		this.loadOrganizationByIdLoading = true;
		this.organizationLoaded = false;
	}
	@Mutation [Mutations.LOAD_ORGANIZATION_BY_ID_SUCCESS]({ organization }: { organization: OrganizationModel }): void {
		const index = this.organizations.findIndex(o => o.id === organization.id);
		if(index > -1){
			this.organizations.splice(index, 1, organization);
		}else{
			this.organizations.push(organization);
		}		
		this.loadOrganizationByIdLoading = false;
		this.organizationLoaded = true;
	}
	@Mutation [Mutations.LOAD_ORGANIZATION_BY_ID_FAILURE](error: any): void {
		this.loadOrganizationByIdLoading = false;
		this.organizationLoaded = false;
	}

	loadOrganizationTeamsLoading: boolean = false;
	loadOrganizationTeamsInitialized: boolean = false;
	loadOrganizationTeamsError: string = null;
	totalTeams: number = 0;
	teamList: TeamModel[] = [];
	@Action({
		rawError: true,
	}) async loadOrganizationTeams({ organizationId }: { organizationId: string }): Promise<void> {
		this.context.commit(Mutations.LOAD_ORGANIZATION_TEAMS);
	try {
			const teams = await organizationApi.getOrganizationTeams({organizationId});
			this.context.commit(Mutations.LOAD_ORGANIZATION_TEAMS_SUCCESS, { teams });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.LOAD_ORGANIZATION_TEAMS_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_ORGANIZATION_TEAMS](): void {
		this.loadOrganizationTeamsLoading = true;
		this.loadOrganizationTeamsError = null;
	}
	@Mutation [Mutations.LOAD_ORGANIZATION_TEAMS_SUCCESS]({ teams }: { teams: TeamModel[] }): void {
		this.teamList = teams;
		this.totalTeams = teams.length;
		this.loadOrganizationTeamsLoading = false;
		this.loadOrganizationTeamsInitialized = true;
	}
	@Mutation [Mutations.LOAD_ORGANIZATION_TEAMS_FAILURE](error: any): void {
		this.loadOrganizationTeamsLoading = false;
		this.loadOrganizationTeamsError = "Error loading Organization teams";
	}

	loadOrganizationStaffLoading: boolean = false;
	loadOrganizationStaffInitialized: boolean = false;
	staffList: StaffMember[] = [];
	@Action({
		rawError: true,
	}) async loadOrganizationStaff({ organizationId }: { organizationId: string }): Promise<void> {
		this.context.commit(Mutations.LOAD_ORGANIZATION_STAFF);
		try {
			const staff = await organizationApi.getOrganizationStaff({organizationId});
			this.context.commit(Mutations.LOAD_ORGANIZATION_STAFF_SUCCESS, { staff });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.LOAD_ORGANIZATION_STAFF_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_ORGANIZATION_STAFF](): void {
		this.loadOrganizationStaffLoading = true;
	}
	@Mutation [Mutations.LOAD_ORGANIZATION_STAFF_SUCCESS]({ staff }: { staff: StaffMember[] }): void {
		this.staffList = staff;
		this.loadOrganizationStaffLoading = false;
		this.loadOrganizationStaffInitialized = true;
	}
	@Mutation [Mutations.LOAD_ORGANIZATION_STAFF_FAILURE](error: any): void {
		this.loadOrganizationStaffLoading = false;
	}

	removeStaffLoading: boolean = false;
	@Action({
		rawError: true,
	}) async removeStaff({ organizationId, staffId }: { organizationId: string, staffId: string }): Promise<void> {
		this.context.commit(Mutations.REMOVE_STAFF);
		try {
			const organization = await organizationApi.removeStaff({ organizationId, staffId});
			this.context.commit(Mutations.REMOVE_STAFF_SUCCESS, { staffId });
			this.context.commit(Mutations.LOAD_ORGANIZATION_BY_ID_SUCCESS, { organization });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.REMOVE_STAFF_FAILURE, e);
		}
	}

	@Mutation [Mutations.REMOVE_STAFF](): void {
		this.removeStaffLoading = true;
	}
	@Mutation [Mutations.REMOVE_STAFF_SUCCESS]({ staffId }: { staffId: string }): void {
		const index = this.staffList.findIndex(s => s.id === staffId);
		if(index > -1) this.staffList.splice(index, 1);
		this.removeStaffLoading = false;
	}
	@Mutation [Mutations.REMOVE_STAFF_FAILURE](error: any): void {
		this.removeStaffLoading = false;
	}

	patchOrganizationLoading: boolean = false;
	@Action({
		rawError: true,
	}) async patchOrganization(organization: OrganizationModel, options: OrganizationCreateOptions = {}): Promise<void> {
		this.context.commit(Mutations.PATCH_ORGANIZATION);
		try {
			const org = await organizationApi.patch(organization, { params: options });
			this.context.commit(Mutations.PATCH_ORGANIZATION_SUCCESS, { organization: org });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.PATCH_ORGANIZATION_FAILURE, e);
		}
	}

	@Mutation [Mutations.PATCH_ORGANIZATION](): void {
		this.patchOrganizationLoading = true;
	}
	@Mutation [Mutations.PATCH_ORGANIZATION_SUCCESS]({ organization }: { organization: OrganizationModel }): void {
		const index = this.organizations.findIndex(o => o.id === organization.id);
		if(index > -1){
			this.organizations.splice(index, 1, organization);
		}else{
			this.organizations.push(organization);
		}
		this.patchOrganizationLoading = false;
	}
	@Mutation [Mutations.PATCH_ORGANIZATION_FAILURE](error: any): void {
		this.patchOrganizationLoading = false;
	}


	loadOrganizationPlayersLoading: boolean = false;
	loadOrganizationPlayersInitialized: boolean = false;
	totalPlayers: number = 0;
	playersList: AthleteProfileModel[] = [];
	@Action({
		rawError: true,
	}) async loadOrganizationPlayers({ organizationId, query, options }: {
		organizationId: string,
		query: RepositoryQuery<AthleteProfileModel>,
		options: QueryOptions,
	}): Promise<void> {
		this.context.commit(Mutations.QUERY_ORGANIZATION_PLAYERS);
		try {
			const response = await organizationApi.queryOrganizationPlayersEx({ organizationId, query, options });
			this.context.commit(Mutations.QUERY_ORGANIZATION_PLAYERS_SUCCESS, { response });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.QUERY_ORGANIZATION_PLAYERS_FAILURE, e);
		}
	}

	@Mutation [Mutations.QUERY_ORGANIZATION_PLAYERS](): void {
		this.loadOrganizationPlayersLoading = true;
	}
	@Mutation [Mutations.QUERY_ORGANIZATION_PLAYERS_SUCCESS]({ response }: { response: PaginatedResponse<AthleteProfileModel> }): void {
		this.totalPlayers = response.total;
		this.playersList = response.docs;
		this.loadOrganizationPlayersLoading = false;
		this.loadOrganizationPlayersInitialized = true;
	}
	@Mutation [Mutations.QUERY_ORGANIZATION_PLAYERS_FAILURE](error: any): void {
		this.loadOrganizationPlayersLoading = false;
	}
	
	reindexLeaderboardInProgress: boolean = false;
	@Action({
		rawError: true,
	}) async createLeaderboardIndex(orgId: string): Promise<void> {
		this.context.commit(Mutations.REINDEX_LEADERBOARD);
		try {
			const response = await organizationApi.createIndexLeaderboard({organizationId: orgId});
			this.context.commit(Mutations.REINDEX_LEADERBOARD_SUCCESS, { response });
		} catch(e) {
			console.error("Failed to reindex leaderboard", e);
			this.context.commit(Mutations.REINDEX_LEADERBOARD_FAILURE, e);
		}
	}
	@Mutation [Mutations.REINDEX_LEADERBOARD](): void {
		this.reindexLeaderboardInProgress = true;
	}
	@Mutation [Mutations.REINDEX_LEADERBOARD](): void {
		this.reindexLeaderboardInProgress = false;
	}
	@Mutation [Mutations.REINDEX_LEADERBOARD](): void {
		this.reindexLeaderboardInProgress = false;
	}
}
